const initialState = {
    lang: 'fr',
    currentSearchQuery: '',
    currentSearchFilters: null,
    currentMainTag: '',
    currentprofile: '',
    laboEmptyOther: '',
    filtersChange: 0,
    filtersThemesNumber: 0,
    filtersYearNumber: 0,
    filtersTypeNumber: 0,
    filtersHolderNumber: 0,
    filtersProfilesNumber: 0,
    hasFixedProfileNav: false,
    hasLSearchPanelOpen: false,
    hasMainNavOpen: false,
    navImage: null,
    savedSearchState: {}
}

export default function rootReducer(state = initialState, action) {
    switch(action.type) {
        case 'CHANGE_LANG':
            return {
                ...state,
                lang: action.payload
            }
        case 'SET_CURRENT_SEARCH_QUERY':
            return {
                ...state,
                currentSearchQuery: action.payload
            }
        case 'SET_CURRENT_SEARCH_FILTERS':
            return {
                ...state,
                currentSearchFilters: action.payload
            }
        case 'SET_CURRENT_MAIN_TAG':
            return {
                ...state,
                currentMainTag: action.payload
            }
        case 'SET_CURRENT_PROFILE_TAG':
            return {
                ...state,
                currentprofile: action.payload
            }
        case 'SET_LABO_EMPTY_OTHER':
            return {
                ...state,
                laboEmptyOther: action.payload
            }
        case 'SET_FILTERS_CHANGE':
            return {
                ...state,
                filtersChange: action.payload
            }
        case 'SET_FILTERS_THEMES_NUMBER':
            return {
                ...state,
                filtersThemesNumber: action.payload
            }
        case 'SET_FILTERS_YEAR_NUMBER':
            return {
                ...state,
                filtersYearNumber: action.payload
            }
        case 'SET_FILTERS_HOLDER_NUMBER':
            return {
                ...state,
                filtersHolderNumber: action.payload
            }
        case 'SET_FILTERS_PROFILES_NUMBER':
            return {
                ...state,
                filtersProfilesNumber: action.payload
            }
        case 'SET_FILTERS_TYPE_NUMBER':
            return {
                ...state,
                filtersTypeNumber: action.payload
            }
        case 'SET_HAS_SEARCH_PANEL_OPEN':
            return {
                ...state,
                hasSearchPanelOpen: action.payload
            }
        case 'SET_HAS_MAIN_NAV_OPEN':
            return {
                ...state,
                hasMainNavOpen: action.payload
            }
        case 'SAVE_SEARCH_STATE':
            return {
                ...state,
                savedSearchState: action.payload
            }
        case 'CHANGE_NAV_IMAGE':
            return {
                ...state,
                navImage: action.payload
            }
        default:
            return state
    }
}